<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <h4 class="cardD">Complementa la información</h4>
    <!-- <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card> -->

    <h4 class="cardD">Domicilio de la sociedad</h4>
    <!-- <div class="row">
      <div class="col-4">
        <base-input disabled type="text" label="País" placeholder="País" v-model="constitutiva.pais">
        </base-input>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_fedatario_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio'
        }">
          <label>Entidad Federativa</label>
          <select class="custom-select" :disabled="constitutiva.disabledComplementa"
            v-model="constitutiva.entidadDomicilio">
            <option v-for="option in optionsEstados" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="constitutiva.entidad">
        <base-input :disabled="constitutiva.disabledComplementa" type="text" label="Dekegación/Municipio"
          placeholder="Municipio" v-model="constitutiva.municipioDomicilio">
        </base-input>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-4">
        <label>País</label>
        <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control"
          :disabled="constitutiva.disabledComplementa">
          <option v-for="option in countries" :key="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="entidad" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'La entidad es obligatoria' }">
          <label>Entidad Federativa</label>
          <select @change="fetchCitiesByState" v-model="selectState" class="form-control"
            :disabled="constitutiva.disabledComplementa">
            <option v-if="isEmptyStates" value="noStates" selected>
              {{ noStatesText }}
            </option>
            <option v-for="option in states" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="constitutiva.entidad != 'Ciudad de México'">
        <label>Demarcación Territorial o Municipio</label>
        <select v-model="selectCity" class="form-control" :disabled="constitutiva.disabledComplementa">
          <option v-if="isEmptyCities" value="noCities" selected>
            {{ noCitiesText }}
          </option>
          <option v-for="option in cities" :key="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <h4 class="cardD">Accionistas</h4>

    <div class="mb-4">
      <div v-if="constitutiva.acciones_valor_nominal">
        <label for="">Acciones con valor nominal de un peso</label>
        <b-form-checkbox v-model="constitutiva.acciones_valor_nominal" :disabled="constitutiva.disabledComplementa">
        </b-form-checkbox>
      </div>
      <div v-else>
        <label for="">Acciones sin valor nominal</label>
        <b-form-checkbox v-model="constitutiva.acciones_valor_nominal" :disabled="constitutiva.disabledComplementa">
        </b-form-checkbox>
      </div>
    </div>

    <label class="leyenda">
      Edita cada uno de los accionistas para llenar todos los datos necesarios.
      Serás enviado al apartado de comparecientes.
    </label>

    <div class="row">
      <div class="col-12">
        <label for="">Cuadro Accionario</label>
        <b-table hover :fields="fieldsCuadro" :items="constitutiva.accionistas">
          <!-- <template #cell(capital_variable)="{ item }">
            <b-input v-model="item.capital_variable" type="number"
              :disabled="constitutiva.tiene_capital_variable && constitutiva.disabledComplementa"></b-input>
          </template> -->
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editCompareciente(item)">
              <b-icon-pencil />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.persona == 'Persona Moral'">
              <div v-if="item.rfc">
                <div class="circle completado_green" v-if="item.rfc.length === 12">
                  <span class="number"><i class="fa fa-check"> </i> </span>
                </div>
                <div class="circle completado_yellow" v-else>
                  <span class="number"><i class="fa fa-minus"> </i> </span>
                </div>
              </div>
              <div v-else>
                <div class="circle completado_yellow">
                  <span class="number"><i class="fa fa-minus"> </i> </span>
                </div>
              </div>
            </div>
            <div v-else-if="item.persona == 'Persona Física'">
              <div v-if="item.rfc">
                <div class="circle completado_green" v-if="item.rfc.length === 13">
                  <span class="number"><i class="fa fa-check"> </i> </span>
                </div>
                <div class="circle completado_yellow" v-else>
                  <span class="number"><i class="fa fa-minus"> </i> </span>
                </div>
              </div>
              <div v-else>
                <div class="circle completado_yellow">
                  <span class="number"><i class="fa fa-minus"> </i> </span>
                </div>
              </div>
            </div>
          </template>
        </b-table>

        <label for="">¿Tendrá cláusula de admisión de extranjeros?</label>
        <div v-if="
          constitutiva.capital_extranjero === 1 ||
          constitutiva.capital_extranjero === true
        ">
          <b-form-checkbox v-model="constitutiva.clausula_extranjeros" disabled>
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox v-model="constitutiva.clausula_extranjeros" :disabled="constitutiva.disabledComplementa">
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <h4 class="cardD mt-3">Administradores</h4>
    <label class="leyenda">
      Agrega el RFC a cada uno de los admnistradores.
    </label>

    <b-modal no-close-on-backdrop centered ref="administrador-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putAdministrador)">
          <ModalOrganosAdministradores :currentAdministrador="currentAdministrador" @submit.prevent :complete="true" />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="vigilancia-modal" title="" hide-footer id="modalV" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putVigilancia)">
          <ModalOrganosVigilancia :currentVigilancia="currentVigilancia" @submit.prevent :complete="true" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="row">
      <div class="col-12">
        <label v-if="
          constitutiva.tipo_societario === 'SA' ||
          constitutiva.tipo_societario === 'SAPI'
        ">Administradores</label>
        <label v-if="constitutiva.tipo_societario === 'S de RL'">Gerentes</label>
        <b-table hover :fields="fieldsCuadroAdmin" :items="constitutiva.administradores">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editAdministrador(item)" :disabled="constitutiva.disabledComplementa">
              <b-icon-pencil />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.rfc">
              <div class="circle completado_green" v-if="item.rfc.length === 13">
                <span class="number"><i class="fa fa-check"> </i> </span>
              </div>
              <div class="circle completado_yellow" v-else>
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
            <div v-else>
              <div class="circle completado_yellow">
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <h4 class="cardD mt-3">Vigilancia</h4>
    <label class="leyenda"> Agrega el RFC a cada uno de los comisarios. </label>

    <div v-if="
      constitutiva.tipo_administracion === 'Consejo de Administración' ||
      constitutiva.tipo_administracion === 'Consejo de Gerentes'
    "></div>

    <div class="row">
      <div class="col md-12">
        <label for="">Comisario u Órgano de Vigilancia</label>
        <div>
          <label class="mb-12 leyenda" v-if="constitutiva.tipo_societario === 'S de RL'">
            Campo opcional
          </label>
        </div>

        <b-table hover :fields="fieldsCuadroVigilancia" :items="constitutiva.vigilancia">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editVigilancia(item)" :disabled="constitutiva.disabledComplementa">
              <b-icon-pencil />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.rfc">
              <div class="circle completado_green" v-if="item.rfc.length === 13">
                <span class="number"><i class="fa fa-check"> </i> </span>
              </div>
              <div class="circle completado_yellow" v-else>
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
            <div v-else>
              <div class="circle completado_yellow">
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <h4 class="cardD mt-3">Representantes y Apoderados</h4>
    <label class="leyenda"> Agrega el RFC a cada uno de los apoderados. </label>

    <b-modal no-close-on-backdrop centered ref="apoderado-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putApoderado)">
          <ModalApoderados :currentApoderado="currentApoderado" @submit.prevent :options_facultades="options_facultades"
            :complete="true" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="row">
      <div class="col-12">
        <label for="">Apoderados</label>
        <b-table hover :fields="fieldsCuadroApoderados" :items="constitutiva.apoderados">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editApoderado(item)" :disabled="constitutiva.disabledComplementa">
              <b-icon-pencil />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.rfc">
              <div class="circle completado_green" v-if="item.rfc.length === 13">
                <span class="number"><i class="fa fa-check"> </i> </span>
              </div>
              <div class="circle completado_yellow" v-else>
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
            <div v-else>
              <div class="circle completado_yellow">
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <div class="mt-4">
      <div class="row" v-if="constitutiva.disabledComplementa === false">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledComplementa">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="!canValidate"
            @click="validar()">Validar</b-button>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledComplementa">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="constitutiva.disabledComplementa"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import ModalOrganosAdministradores from "@/views/clients/procesos/constitucion/infoBase/modales/ModalOrganosAdministradores.vue";
import ModalOrganosVigilancia from "@/views/clients/procesos/constitucion/infoBase/modales/ModalOrganosVigilancia.vue";
import ModalApoderados from "@/views/clients/procesos/constitucion/infoBase/modales/ModalApoderados.vue";

export default {
  name: "TabComplementaInformacion",

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),

    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),

    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        {
          key: "nombreCompleto",
          label: "Nombre",
          class: "text-center"
        },
        // { key: "paterno", label: "Paterno", class: "text-center" },
        // { key: "materno", label: "Materno", class: "text-center" },
        // { key: "email", label: "Email", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "porcentaje", label: "Porcentaje", class: "text-center" },
        { key: "capital_fijo", label: "Capital Fijo", class: "text-center" },
        // {
        //   key: "capital_variable",
        //   label: "Capital Variable",
        //   class: "text-center",
        //   thStyle: { width: '150px' },
        //   tdStyle: { width: '150px' }
        // },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadro;
    },
    fieldsCuadroAdmin() {
      let fieldsCuadroAdmin = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "cargo", label: "Cargo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadroAdmin;
    },
    fieldsCuadroVigilancia() {
      let fieldsCuadroVigilancia = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "cargo", label: "Cargo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadroVigilancia;
    },
    fieldsCuadroApoderados() {
      let fieldsCuadroApoderados = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadroApoderados;
    }
  },

  created() {
    this.calcularCapitales();
    this.getFacultades();
    this.clausula();
    this.enableSave();
  },

  components: {
    ModalOrganosAdministradores,
    ModalOrganosVigilancia,
    ModalApoderados
  },

  props: {
    constitutiva: { type: Object },
    cat_facultades: { type: Array }
  },

  watch: {
    "constitutiva.accionistas": {
      handler: function (val, oldVal) {
        this.enableSave();
      },
      deep: true
    },
    "constitutiva.administradores": {
      handler: function (val, oldVal) {
        this.enableSave();
      },
      deep: true
    },
    "constitutiva.vigilancia": {
      handler: function (val, oldVal) {
        this.enableSave();
      },
      deep: true
    },
    "constitutiva.apoderados": {
      handler: function (val, oldVal) {
        this.enableSave();
      },
      deep: true
    },

    "selectCountry": function (val, oldVal) {
      // this.constitutiva.pais = this.selectCountry;
    },
    "selectState": function (val, oldVal) {
      // this.constitutiva.entidadDomicilio = this.selectState;
    },
    "selectCity": function (val, oldVal) {
      // this.constitutiva.municipioDomicilio = this.selectCity;
    }
  },

  async mounted() {
    await this.fetchCountries();

    if (this.constitutiva.pais) {
      this.selectCountry = this.constitutiva.pais;

      await this.fetchStatesByCountry();

      // if (this.constitutiva.pais) {
      //   await this.getExistCountry(this.constitutiva.pais);
      // }
      // if (this.constitutiva.entidadDomicilio) {
      //   await this.getExistState(this.constitutiva.entidadDomicilio);
      // }
      // if (this.constitutiva.municipioDomicilio) {
      //   await this.getExistCity(this.constitutiva.municipioDomicilio);
      // }
    }
  },

  data() {
    return {
      optionsEstados: [
        { text: "Aguascalientes", value: "Aguascalientes" },
        { text: "Baja California", value: "Baja California" },
        { text: "Baja California Sur", value: "Baja California Sur" },
        { text: "Campeche", value: "Campeche" },
        { text: "Chiapas", value: "Chiapas" },
        { text: "Chihuahua", value: "Chihuahua" },
        { text: "Coahuila", value: "Coahuila" },
        { text: "Colima", value: "Colima" },
        { text: "Ciudad de México", value: "Ciudad de México" },
        { text: "Durango", value: "Durango" },
        { text: "Estado de México", value: "Estado de México" },
        { text: "Guanajuato", value: "Guanajuato" },
        { text: "Guerrero", value: "Guerrero" },
        { text: "Hidalgo", value: "Hidalgo" },
        { text: "Jalisco", value: "Jalisco" },
        { text: "Michoacán ", value: "Michoacán " },
        { text: "Morelos", value: "Morelos" },
        { text: "Nayarit", value: "Nayarit" },
        { text: "Nuevo León", value: "Nuevo León" },
        { text: "Oaxaca", value: "Oaxaca" },
        { text: "Puebla", value: "Puebla" },
        { text: "Querétaro", value: "Querétaro" },
        { text: "Quintana Roo", value: "Quintana Roo" },
        { text: "San Luis Potosí", value: "San Luis Potosí" },
        { text: "Sinaloa", value: "Sinaloa" },
        { text: "Sonora", value: "Sonora" },
        { text: "Tabasco", value: "Tabasco" },
        { text: "Tamaulipas", value: "Tamaulipas" },
        { text: "Tlaxcala", value: "Tlaxcala" },
        { text: "Veracruz ", value: "Veracruz " },
        { text: "Yucatán", value: "Yucatán" },
        { text: "Zacatecas", value: "Zacatecas" }
      ],

      loading: false,
      comparecientes: [],
      options_comparecientes: [],
      selectedCompareciente: null,
      currentComparecienteId: null,
      currentCompareciente: null,
      totalPorcentajes: null,
      canValidate: false,

      optionsSaSapi: [
        { text: "Administrador único", value: "Administrador único" },
        {
          text: "Consejo de Administración",
          value: "Consejo de Administración"
        }
      ],
      optionsSrl: [
        { text: "Gerente General", value: "Gerente General" },
        { text: "Consejo de Gerentes", value: "Consejo de Gerentes" }
      ],
      currentAdministrador: null,
      currentAdministradorId: null,
      currentVigilancia: null,
      currentVigilanciaId: null,

      currentApoderado: null,
      currentApoderadoId: null,
      options_facultades: [],

      form: {
        accion: null,

        capital_fijo: null,
        capital_variable: null,
        accionistas: [],
        totalPorcentajesF: null,

        tipo_administracion: null,
        administradores: [],
        presidente_voto: null,
        vigilancia: [],

        apoderados: []
      },

      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",
    };
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.constitutiva.pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    enableSave() {
      let count = 0;
      this.constitutiva.accionistas.forEach(item => {
        if (item.rfc) {
          if (item.persona == "Persona Moral") {
            if (item.rfc.length != 12) {
              count++;
            }
          } else if (item.persona == "Persona Física") {
            if (item.rfc.length != 13) {
              count++;
            }
          }
        } else {
          count++;
        }
      });
      this.constitutiva.administradores.forEach(item => {
        if (item.rfc) {
          if (item.rfc.length != 13) {
            count++;
          }
        } else {
          count++;
        }
      });
      this.constitutiva.vigilancia.forEach(item => {
        if (item.rfc) {
          if (item.rfc.length != 13) {
            count++;
          }
        } else {
          count++;
        }
      });
      this.constitutiva.apoderados.forEach(item => {
        if (item.rfc) {
          if (item.rfc.length != 13) {
            count++;
          }
        } else {
          count++;
        }
      });

      if (count === 0) {
        this.canValidate = true;
      } else {
        this.canValidate = false;
      }
    },

    clausula() {
      if (
        this.constitutiva.capital_extranjero === 1 ||
        this.constitutiva.capital_extranjero === true
      ) {
        this.constitutiva.clausula_extranjeros = true;
      } else if (
        this.constitutiva.clausula_extranjeros === true ||
        this.constitutiva.clausula_extranjeros === 1
      ) {
        this.constitutiva.clausula_extranjeros = true;
      } else {
        this.constitutiva.clausula_extranjeros = false;
      }
    },

    async getFacultades() {
      this.loading = true;

      try {
        this.cat_facultades.forEach(element => {
          const obj = {
            text: element.facultad,
            value: element.facultad
          };
          this.options_facultades.push(obj);
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    calcularCapitales() {
      let totalPorcentajes = 0;
      for (let i = 0; i < this.constitutiva.accionistas.length; i++) {
        let capital_fijo =
          (this.constitutiva.accionistas[i].porcentaje *
            this.constitutiva.capital_fijo) /
          100;

        this.constitutiva.accionistas[i].capital_fijo = capital_fijo;

        let capital_variable =
          (this.constitutiva.accionistas[i].porcentaje *
            this.constitutiva.capital_variable) /
          100;
        this.constitutiva.accionistas[i].capital_variable = capital_variable;

        totalPorcentajes =
          totalPorcentajes +
          parseFloat(this.constitutiva.accionistas[i].porcentaje);

        var a = Object.assign({}, this.constitutiva.accionistas[i]);
        this.constitutiva.accionistas.splice([i], 1, a);
      }
      // this.totalPorcentajes = totalPorcentajes;
    },

    editCompareciente(item) {
      // this.$router.push(
      //   `/dashboard/comparecientes/editcomparecientes/${item.id}`
      // );
      // let accion = "nuevo";
      // if (this.constitutiva.accion === "edit") {
      //   accion = "edit";
      // }
      this.$router.push({
        path: `/dashboard/comparecientes/editcomparecientes/${item.id}`,
        query: {
          proceso: "validconstitucion",
          procesoId: this.constitutiva.id,
          accion: this.constitutiva.accion
        }
      });
      //mandar con alguna bandera para que regrese al consti_id y node completa_info
    },

    editAdministrador(item) {
      this.$refs["administrador-modal"].show();
      this.currentAdministrador = item;
    },

    putAdministrador() {
      if (!this.currentAdministrador.id) {
        let id = this.constitutiva.administradores.length + 1;
        this.currentAdministrador.id = id;
        var a = Object.assign({}, this.currentAdministrador);
        this.constitutiva.administradores.push(a);
      } else {
        let index = this.constitutiva.administradores.findIndex(
          // (o) => o.id === this.currentAdministradorId
          o => o.id === this.currentAdministrador.id
        );
        var a = Object.assign({}, this.currentAdministrador);
        // this.constitutiva.administradores[index] = a;
        this.constitutiva.administradores.splice([index], 1, a);
      }
      this.$refs["administrador-modal"].hide();
    },

    async editVigilancia(item) {
      this.$refs["vigilancia-modal"].show();
      this.currentVigilancia = item;
      // const valid = await this.$refs.provider.errors(this.currentVigilancia);
    },

    putVigilancia() {
      if (!this.currentVigilancia.id) {
        let id = this.constitutiva.vigilancia.length + 1;
        this.currentVigilancia.id = id;
        var a = Object.assign({}, this.currentVigilancia);
        this.constitutiva.vigilancia.push(a);
      } else {
        let index = this.constitutiva.vigilancia.findIndex(
          o => o.id === this.currentVigilancia.id
        );
        var a = Object.assign({}, this.currentVigilancia);
        // this.constitutiva.vigilancia[index] = a;
        this.constitutiva.vigilancia.splice([index], 1, a);
      }
      this.$refs["vigilancia-modal"].hide();
    },

    editApoderado(item) {
      this.$refs["apoderado-modal"].show();
      this.currentApoderado = item;
    },

    putApoderado() {
      if (!this.currentApoderado.id) {
        let id = this.constitutiva.apoderados.length + 1;
        this.currentApoderado.id = id;
        var a = Object.assign({}, this.currentApoderado);
        this.constitutiva.apoderados.push(a);
      } else {
        let index = this.constitutiva.apoderados.findIndex(
          o => o.id === this.currentApoderado.id
        );
        var a = Object.assign({}, this.currentApoderado);
        // this.constitutiva.apoderados[index] = a;
        this.constitutiva.apoderados.splice([index], 1, a);
      }
      this.$refs["apoderado-modal"].hide();
    },

    validar() {
      this.$emit("validaForm", "ComplementaInformacion");
    }
  }
};
</script>

<style scoped>
.spaceL {
  margin-left: 1%;
}

.completado_red {
  background-color: #e60505 !important;
}

.completado_green {
  background-color: #9acd32 !important;
}

.circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.led-green {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #43eb34;
  /* #690 */
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
