import { render, staticRenderFns } from "./Notificaciones.vue?vue&type=template&id=2e8bc13a&scoped=true"
import script from "./Notificaciones.vue?vue&type=script&lang=js"
export * from "./Notificaciones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e8bc13a",
  null
  
)

export default component.exports