<template>
  <div class="container" id="container-openpay">
    <!-- <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div> -->

    <div class="bkng-tb-cntnt">
      <div class="row">
        <div class="col-12">
          <h4 class="cardD mt-3">Saldo actual</h4>
          <h3 class="saldo">$ {{ this.saldo_formatted }}</h3>
        </div>
      </div>

      <div v-if="this.suficiente">
        <label for="">Costo total del servicio ${{ this.precio_total }}</label>
      </div>

      <div v-if="this.suficiente">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="pagar()">Pagar</b-button>
      </div>

      <div class="mt-3" v-if="!suficiente">
        El saldo en el monedero no es suficiente para realizar esta compra por
        ${{ this.precio_total }}. Por favor recargue el Monedero de Correduría
        Digital en el apartado de "Monedero"
      </div>
    </div>

    <b-overlay :show="loading" no-wrap></b-overlay>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";

import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "Monedero",

  props: {
    cotizacion_id: { type: Number },
    precio_total: { type: Number },
    clientes_id: { type: Number },
    servicio: { type: String }
  },

  data: () => ({
    loading: false,
    saldo: 0,
    saldo_formatted: 0,
    suficiente: true
  }),

  created() {
    // console.log("cotizacion_id");
    // console.log(this.cotizacion_id);
    // console.log("clientes_id");
    // console.log(this.clientes_id);
    this.determinar_saldo();
  },

  methods: {
    async determinar_saldo() {
      this.saldo = await FuncionesApi.getSaldoCliente(this.clientes_id, {});
      this.saldo_formatted = this.saldo.toLocaleString();
      let precio_total_sin = this.precio_total.toString().replace(",", "");
      // precio_total_sin = precio_total_sin.replace(".", "");
      let precio_total_numb = Number(precio_total_sin);

      if (Number(this.saldo) >= precio_total_numb) {
        this.suficiente = true;
      } else {
        this.suficiente = false;
      }
    },

    async pagar() {
      try {
        this.loading = true;

        const email = storage.getItem("user");
        const pagoConMonederoRequest = {
          clientes_id: this.clientes_id,
          monto: this.precio_total,
          servicio: this.servicio,
          email: email,
          cotizaciones_id: this.cotizacion_id
        }

        await FuncionesApi.pagoConMonedero(pagoConMonederoRequest);

        this.$toast.success("Pagado", {
          timeout: 6000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.$emit("payCotizacion", true);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.saldo {
  text-align: center;
}
</style>
